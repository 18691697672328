/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

:root {
  --light_gray: #e6e6e6;
}

.footer_bg {
  background: -o-linear-gradient(
    184.58deg,
    #0e0b34 -3.07%,
    rgba(14, 11, 52, 0) 102.57%
  );
  background: linear-gradient(
    265.42deg,
    #0e0b34 -3.07%,
    rgba(14, 11, 52, 0) 102.57%
  );
}

.footer_links {
  text-decoration: none;
  color: var(--light_gray);
  opacity: 0.8;
  font-size: var(--font-md);
  font-weight: 400;
}

.footer_links:hover {
  color: rgba(171, 122, 255, 1);
}

.footer_logo {
  cursor: pointer;
  max-width: 200px;
}

.footer_heading {
  font-size: var(--font-lg);
  font-weight: 600;
}

.footer_para {
  font-size: var(--font-sm);
  font-weight: 400;
  color: var(--gray);
}

.border_top_custom {
  border-top: 1px solid rgba(188, 188, 188, 0.4);
}

.social_icons a {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 8px;
}

.social_icons a svg {
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.social_icons a:hover svg {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.social_icons a:hover::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

/*===================== WHAT IS PORTAL CSS===================== */
.bg_after_color::after {
  content: "";
  position: absolute;
  background: rgba(13, 9, 56, 1);
  border-radius: 200px 0 0 200px;
  left: 0;
  top: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
}

.bg_after_color2::after {
  content: "";
  position: absolute;
  background: rgba(13, 9, 56, 1);
  border-radius: 0 200px 200px 0;
  right: 0;
  top: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
}

.mw_250 {
  max-width: 250px;
}

.mw_250 {
  max-width: 250px;
}

.text_01 {
  position: absolute;
  right: 0;
  top: 5%;
  color: var(--white);
  font-size: 15vw;
  opacity: 0.1 !important;
  z-index: 1;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.text_02 {
  position: absolute;
  left: 0;
  bottom: 0%;
  color: var(--white);
  font-size: 15vw;
  opacity: 0.1 !important;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  z-index: 1;
}

/* NAVBAR CSS */
.header_logo {
  max-width: 150px;
}

.nav-list-items li {
  font-size: var(--font-lg);
  color: var(--color-black);
  font-weight: 400;
  cursor: pointer;
}

.navbar-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1;
}

.navbar-overlay {
  position: fixed;
  width: 100%;
  height: 0%;
  z-index: 10;
  top: -800px;
  left: -800px;
  background: rgb(19, 19, 19);
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 0px 0px 100px 0px;
}

.active-nav-overlay #root {
  overflow-y: hidden;
  max-height: 100vh;
}

.active-nav-overlay .back-to-top div {
  display: none;
}

.active-nav-overlay .z-1 {
  z-index: inherit;
}

.active-nav-overlay .navbar-overlay {
  height: 100vh;
  top: 0;
  left: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 0;
}

.navbar-overlay li {
  font-size: 3vw;
  color: var(--color-white);
  font-weight: 400;
  position: relative;
  cursor: pointer;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  list-style-type: none;
}

.navbar-overlay li::after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background: var(--color-light-green);
  left: 50%;
  bottom: 0;
  border-radius: 30px;
}

.navbar-overlay li:hover::after {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.active-nav-overlay ul li:nth-child(1) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(2) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(3) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(4) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(5) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(6) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(7) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(8) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(9) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* ================ PRELOADER ================ */

@-webkit-keyframes animate_links {
  from {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 1;
  }
}

@keyframes animate_links {
  from {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 1;
  }
}

.h-screen {
  height: 100vh;
}

.fix-screen-height {
  height: 100vh;
  overflow: hidden;
}

/* HAMBURGER ICON STYLE  */
#nav-icon1 {
  width: 40px;
  height: 35px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#208594),
    to(#0c3160)
  );
  background: -o-linear-gradient(top, #208594 0%, #0c3160 100%);
  background: linear-gradient(180deg, #208594 0%, #0c3160 100%);
  border-radius: 7px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 13px;
}

#nav-icon1 span:nth-child(3) {
  top: 26px;
}

.active-nav-overlay #nav-icon1 {
  z-index: 20;
}

.active-nav-overlay #nav-icon1 span:nth-child(1) {
  top: 13px;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.active-nav-overlay #nav-icon1 span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.active-nav-overlay #nav-icon1 span:nth-child(3) {
  top: 13px;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.overlay_links {
  font-size: 30px !important;
}

.navbar-link {
  color: white;
  font-weight: 400;
  position: relative;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.navbar-link::after {
  position: absolute;
  content: "";
  background-color: rgba(171, 122, 255, 1);
  width: 100%;
  height: 1.5px;
  bottom: -5px;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border-radius: 5px;
}

.navbar-link:hover::after {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.navbar-link:hover {
  color: rgba(171, 122, 255, 1);
}

.hero_bg {
  background-image: url("./assets/img/hero-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.hero_heading {
  font-size: var(--font-4xl);
  font-weight: 700;
  color: var(--white);
  font-family: "Poppins", sans-serif;
  max-width: 600px;
}

.border_radius_50 {
  border-radius: 50%;
}

.border_btn {
  background: none;
  color: var(--white);
  border: 1px solid rgba(94, 0, 255, 1);
  box-sizing: border-box;
  min-width: 150px;
  font-weight: 600;
  border-radius: 50px;
  padding: 12px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  font-family: "Poppins", sans-serif;
}

.common_btn {
  background: rgba(94, 0, 255, 1);
  border: 1px solid rgba(94, 0, 255, 1);
  color: var(--white);
  min-width: 150px;
  border-radius: 50px;
  font-weight: 600;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  padding: 12px;
  font-family: "Poppins", sans-serif;
}

.common_btn:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-box-shadow: 0px 0px 25px rgb(140, 90, 227);
  box-shadow: 0px 0px 25px rgb(140, 90, 227);
}

.common_btn .btn_arrow_img,
.border_btn .btn_arrow_img {
  transition: all 0.15s ease-in-out;
}

.common_btn:hover .btn_arrow_img,
.border_btn:hover .btn_arrow_img {
  margin-left: 10px;
  display: inline-block;
}

.border_btn:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  background: rgb(140, 90, 227);
  border-radius: 50px;
  -webkit-box-shadow: 0px 0px 25px rgb(140, 90, 227);
  box-shadow: 0px 0px 25px rgb(140, 90, 227);
  border-color: rgb(140, 90, 227);
}

@keyframes appearAfterDelay {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.switch_btn {
  animation: appearAfterDelay 4s ease-in-out;
  position: absolute;
  bottom: 28px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  width: 17px !important;
  height: 50px;
  border: 1px solid var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
}

.switch_btn span {
  display: block;
  top: 7px;

  position: absolute;
  width: 2px;
  height: 10px;
  border: 2px solid white;
  background-color: var(--white);
  animation: upDown 1.5s ease-in-out infinite alternate-reverse;
  border-radius: 20px;
}

@-webkit-keyframes upDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }
}

@keyframes upDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }
}

.hero_icon_1 {
  position: absolute;
  top: 35%;
  max-width: 80px;
  left: 11%;
  -webkit-animation: animationFramesTwo 30s linear infinite;
  animation: animationFramesTwo 30s linear infinite;
}

.hero_icon_2 {
  position: absolute;
  bottom: 24%;
  left: 45%;
  max-width: 60px;
  -webkit-animation: animationFrameOne 30s linear infinite;
  animation: animationFrameOne 30s linear infinite;
}

.hero_icon_3 {
  position: absolute;
  top: 35%;
  right: 15%;
  max-width: 70px;
  -webkit-animation: animationFramesThree 30s linear infinite;
  animation: animationFramesThree 30s linear infinite;
}

.hero_icon_4 {
  position: absolute;
  top: 15%;
  right: 30%;
  max-width: 50px;
  -webkit-animation: animationFrameOne 30s linear infinite;
  animation: animationFrameOne 30s linear infinite;
}

.hero_icon_5 {
  position: absolute;
  top: 12%;
  right: 55%;
  max-width: 50px;
  -webkit-animation: animationFramesTwo 30s linear infinite;
  animation: animationFramesTwo 30s linear infinite;
}

@-webkit-keyframes animationFramesThree {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(-20px, 1px);
    transform: translate(-20px, 1px);
  }

  40% {
    -webkit-transform: translate(-40px, -20px);
    transform: translate(-40px, -20px);
  }

  60% {
    -webkit-transform: translate(-30px, -40px);
    transform: translate(-30px, -40px);
  }

  80% {
    -webkit-transform: translate(40px, -20px);
    transform: translate(40px, -20px);
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes animationFramesThree {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(-20px, 1px);
    transform: translate(-20px, 1px);
  }

  40% {
    -webkit-transform: translate(-40px, -20px);
    transform: translate(-40px, -20px);
  }

  60% {
    -webkit-transform: translate(-30px, -40px);
    transform: translate(-30px, -40px);
  }

  80% {
    -webkit-transform: translate(40px, -20px);
    transform: translate(40px, -20px);
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@-webkit-keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(-20px, 1px) rotate(-36deg);
    transform: translate(-20px, 1px) rotate(-36deg);
  }

  40% {
    -webkit-transform: translate(-40px, -20px) rotate(-72deg);
    transform: translate(-40px, -20px) rotate(-72deg);
  }

  60% {
    -webkit-transform: translate(-30px, -40px) rotate(-108deg);
    transform: translate(-30px, -40px) rotate(-108deg);
  }

  80% {
    -webkit-transform: translate(40px, -20px) rotate(-144deg);
    transform: translate(40px, -20px) rotate(-144deg);
  }

  100% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
}

@keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(-20px, 1px) rotate(-36deg);
    transform: translate(-20px, 1px) rotate(-36deg);
  }

  40% {
    -webkit-transform: translate(-40px, -20px) rotate(-72deg);
    transform: translate(-40px, -20px) rotate(-72deg);
  }

  60% {
    -webkit-transform: translate(-30px, -40px) rotate(-108deg);
    transform: translate(-30px, -40px) rotate(-108deg);
  }

  80% {
    -webkit-transform: translate(40px, -20px) rotate(-144deg);
    transform: translate(40px, -20px) rotate(-144deg);
  }

  100% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
}

@-webkit-keyframes animationFrameOne {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(20px, -1px) rotate(36deg);
    transform: translate(20px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(40px, 20px) rotate(72deg);
    transform: translate(40px, 20px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(30px, 40px) rotate(108deg);
    transform: translate(30px, 40px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 20px) rotate(144deg);
    transform: translate(-40px, 20px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
}

@keyframes animationFrameOne {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(20px, -1px) rotate(36deg);
    transform: translate(20px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(40px, 20px) rotate(72deg);
    transform: translate(40px, 20px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(30px, 40px) rotate(108deg);
    transform: translate(30px, 40px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 20px) rotate(144deg);
    transform: translate(-40px, 20px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
}

@media (min-width: 576px) {
  .w_100 {
    width: 100%;
  }
}

@media (min-width: 1599.98px) {
  .text_01 {
    top: 2%;
    font-size: 250px;
  }

  .mw_250 {
    max-width: 300px;
  }

  .text_02 {
    bottom: -5%;
    font-size: 250px;
  }
}

@media (max-width: 1399.98px) {
  .text_01,
  .text_02 {
    font-size: 140px;
  }

  .text_01 {
    top: 10%;
  }

  .text_02 {
    bottom: 5%;
  }
}

@media (min-width: 999.98px) and (max-width: 1199.98px) {
  .hero_heading {
    max-width: 465px;
  }
}

@media (max-width: 991.98px) {
  .text_02 {
    bottom: 6%;
  }
}

@media (max-width: 767.98px) {
  .bg_after_color::after {
    border-radius: 10px;
    width: 100%;
  }

  .switch_btn {
    width: 12px !important;
    height: 40px;
  }

  .switch_btn span {
    top: 5px;
  }

  @-webkit-keyframes upDown {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    100% {
      -webkit-transform: translateY(19px);
      transform: translateY(19px);
    }
  }

  @keyframes upDown {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    100% {
      -webkit-transform: translateY(19px);
      transform: translateY(19px);
    }
  }

  .bg_after_color2::after {
    border-radius: 10px;
    width: 100%;
  }

  .text_01 {
    font-size: 70px;
    top: -5%;
  }

  .text_02 {
    font-size: 70px;
    top: -5%;
    right: 0 !important;
    left: auto;
  }

  .header_logo {
    max-width: 120px !important;
  }

  .social_icons a {
    width: 28px;
    height: 28px;
    margin: 0 5px;
  }

  .border_btn {
    min-width: 120px;
    padding-top: 11px;
  }

  .common_btn {
    min-width: 120px;
    padding-top: 11px;
  }
}

@media (max-width: 575.98px) {
  .hero_heading {
    max-width: 300px;
    margin: auto;
  }

  .hero_icon_1 {
    max-width: 50px;
  }

  .hero_icon_5 {
    max-width: 40px;
  }

  .hero_icon_4 {
    max-width: 50px;
  }

  .hero_icon_3 {
    max-width: 40px;
  }

  .hero_icon_2 {
    max-width: 40px;
  }

  @-webkit-keyframes animationFramesThree {
    0% {
      -webkit-transform: translate(0);
      transform: translate(0);
    }

    20% {
      -webkit-transform: translate(-10px, 1px);
      transform: translate(-10px, 1px);
    }

    40% {
      -webkit-transform: translate(-20px, -10px);
      transform: translate(-20px, -10px);
    }

    60% {
      -webkit-transform: translate(-15px, -20px);
      transform: translate(-15px, -20px);
    }

    80% {
      -webkit-transform: translate(20px, -10px);
      transform: translate(20px, -10px);
    }

    100% {
      -webkit-transform: translate(0);
      transform: translate(0);
    }
  }

  @keyframes animationFramesThree {
    0% {
      -webkit-transform: translate(0);
      transform: translate(0);
    }

    20% {
      -webkit-transform: translate(-10px, 1px);
      transform: translate(-10px, 1px);
    }

    40% {
      -webkit-transform: translate(-20px, -10px);
      transform: translate(-20px, -10px);
    }

    60% {
      -webkit-transform: translate(-15px, -20px);
      transform: translate(-15px, -20px);
    }

    80% {
      -webkit-transform: translate(20px, -10px);
      transform: translate(20px, -10px);
    }

    100% {
      -webkit-transform: translate(0);
      transform: translate(0);
    }
  }

  @-webkit-keyframes animationFramesTwo {
    0% {
      -webkit-transform: translate(0) rotate(0deg);
      transform: translate(0) rotate(0deg);
    }

    20% {
      -webkit-transform: translate(-10px, 1px) rotate(-36deg);
      transform: translate(-10px, 1px) rotate(-36deg);
    }

    40% {
      -webkit-transform: translate(-20px, -10px) rotate(-72deg);
      transform: translate(-20px, -10px) rotate(-72deg);
    }

    60% {
      -webkit-transform: translate(-15px, -20px) rotate(-108deg);
      transform: translate(-15px, -20px) rotate(-108deg);
    }

    80% {
      -webkit-transform: translate(20px, -10px) rotate(-144deg);
      transform: translate(20px, -10px) rotate(-144deg);
    }

    100% {
      -webkit-transform: translate(0) rotate(0deg);
      transform: translate(0) rotate(0deg);
    }
  }

  @keyframes animationFramesTwo {
    0% {
      -webkit-transform: translate(0) rotate(0deg);
      transform: translate(0) rotate(0deg);
    }

    20% {
      -webkit-transform: translate(-10px, 1px) rotate(-36deg);
      transform: translate(-10px, 1px) rotate(-36deg);
    }

    40% {
      -webkit-transform: translate(-20px, -10px) rotate(-72deg);
      transform: translate(-20px, -10px) rotate(-72deg);
    }

    60% {
      -webkit-transform: translate(-15px, -20px) rotate(-108deg);
      transform: translate(-15px, -20px) rotate(-108deg);
    }

    80% {
      -webkit-transform: translate(20px, -10px) rotate(-144deg);
      transform: translate(20px, -10px) rotate(-144deg);
    }

    100% {
      -webkit-transform: translate(0) rotate(0deg);
      transform: translate(0) rotate(0deg);
    }
  }

  @-webkit-keyframes animationFrameOne {
    0% {
      -webkit-transform: translate(0) rotate(0deg);
      transform: translate(0) rotate(0deg);
    }

    20% {
      -webkit-transform: translate(10px, -1px) rotate(36deg);
      transform: translate(10px, -1px) rotate(36deg);
    }

    40% {
      -webkit-transform: translate(20px, 10px) rotate(72deg);
      transform: translate(20px, 10px) rotate(72deg);
    }

    60% {
      -webkit-transform: translate(15px, 20px) rotate(108deg);
      transform: translate(15px, 20px) rotate(108deg);
    }

    80% {
      -webkit-transform: translate(-20px, 10px) rotate(144deg);
      transform: translate(-20px, 10px) rotate(144deg);
    }

    100% {
      -webkit-transform: translate(0) rotate(0deg);
      transform: translate(0) rotate(0deg);
    }
  }

  @keyframes animationFrameOne {
    0% {
      -webkit-transform: translate(0) rotate(0deg);
      transform: translate(0) rotate(0deg);
    }

    20% {
      -webkit-transform: translate(10px, -1px) rotate(36deg);
      transform: translate(10px, -1px) rotate(36deg);
    }

    40% {
      -webkit-transform: translate(20px, 10px) rotate(72deg);
      transform: translate(20px, 10px) rotate(72deg);
    }

    60% {
      -webkit-transform: translate(15px, 20px) rotate(108deg);
      transform: translate(15px, 20px) rotate(108deg);
    }

    80% {
      -webkit-transform: translate(-20px, 10px) rotate(144deg);
      transform: translate(-20px, 10px) rotate(144deg);
    }

    100% {
      -webkit-transform: translate(0) rotate(0deg);
      transform: translate(0) rotate(0deg);
    }
  }
}

.min_height_100vh {
  min-height: calc(100vh - 80px);
}

.emphasis_color {
  color: rgba(171, 122, 255, 1);
}

.color_change {
  background: -o-linear-gradient(
    359.96deg,
    #c82ef8 0.03%,
    #cd29f9 4.24%,
    #7477ed 10.38%,
    #1fc0e2 19.76%,
    #bf36f7 25.01%,
    #bf35f7 28.74%,
    #7a71ee 37.73%,
    #1ec1e2 48.47%,
    #cb2bf9 56.85%,
    #cb2bf9 60.8%,
    #6f7bed 69.81%,
    #11cde0 78.97%,
    #cd2bf9 83.75%,
    #cd2af9 88.91%,
    #598eea 95.47%,
    #09d4df 99.98%
  );
  background: linear-gradient(
    90.04deg,
    #c82ef8 0.03%,
    #cd29f9 4.24%,
    #7477ed 10.38%,
    #1fc0e2 19.76%,
    #bf36f7 25.01%,
    #bf35f7 28.74%,
    #7a71ee 37.73%,
    #1ec1e2 48.47%,
    #cb2bf9 56.85%,
    #cb2bf9 60.8%,
    #6f7bed 69.81%,
    #11cde0 78.97%,
    #cd2bf9 83.75%,
    #cd2af9 88.91%,
    #598eea 95.47%,
    #09d4df 99.98%
  );

  color: rgba(0, 0, 0, 0);
  -webkit-background-clip: text;
  background-clip: text;
  background-size: 400% 400%;
  -webkit-animation: colorChange 10s ease infinite;
  animation: colorChange 10s ease infinite;
  background-repeat: repeat;
}

@-webkit-keyframes colorChange {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes colorChange {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
