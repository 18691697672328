/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

* {
  padding: 0;
  margin: 0;
}

body {
  color: var(--white);
  background-color: var(--dark-blue) !important;
}

.active-nav-overlay #home {
  z-index: -1;
}

.ff-inter {
  font-family: "Inter", sans-serif;
}

.ff-poppins {
  font-family: "Poppins", sans-serif;
}

:root {
  /* FONT SIZES  */
  --font-sm: 13px;
  --font-md: 16px;
  --font-lg: 18px;
  --font-xl: 25px;
  --font-xxl: 28px;
  --font-2xl: 30px;
  --font-3xl: 40px;
  --font-4xl: 60px;
  /* COLORS  */
  --white: #ffffff;
  --gray: #bcbcbc;
  --sky-blue: #1cc3e2;
  --dark-blue: #04021c;
}

/* --------------------- ONLY-MEDIA-QUERIES --------------------- */
@media (max-width: 1399.98px) {
  :root {
    --font-sm: 13px;
    --font-md: 16px;
    --font-lg: 18px;
    --font-xl: 25px;
    --font-xxl: 26px;
    --font-2xl: 28px;
    --font-3xl: 35px;
    --font-4xl: 50px;
  }
}

@media (max-width: 1199.98px) {
  :root {
    --font-sm: 13px;
    --font-md: 16px;
    --font-lg: 18px;
    --font-xl: 25px;
    --font-xxl: 26px;
    --font-2xl: 27px;
    --font-3xl: 33px;
    --font-4xl: 40px;
  }
}

@media (max-width: 991.98px) {
  :root {
    --font-sm: 14px;
    --font-md: 16px;
    --font-lg: 18px;
    --font-xl: 25px;
    --font-xxl: 26px;
    --font-2xl: 27px;
    --font-3xl: 33px;
    --font-4xl: 35px;
  }
}

@media (max-width: 767.98px) {
  :root {
    --font-sm: 14px;
    --font-md: 16px;
    --font-lg: 18px;
    --font-xl: 25px;
    --font-xxl: 26px;
    --font-2xl: 27px;
    --font-3xl: 30px;
    --font-4xl: 33px;
  }
}

@media (max-width: 575.98px) {
  :root {
    --font-sm: 13px;
    --font-md: 13px;
    --font-lg: 16px;
    --font-xl: 18px;
    --font-xxl: 22px;
    --font-2xl: 24px;
    --font-3xl: 26px;
    --font-4xl: 28px;
  }
}

/*FONT----SIZES-----START*/
.font-sm {
  font-size: var(--font-sm) !important;
}

.font-md {
  font-size: var(--font-md) !important;
}

.font-lg {
  font-size: var(--font-lg) !important;
}

.font-xl {
  font-size: var(--font-xl) !important;
}

.font-xxl {
  font-size: var(--font-xxl) !important;
}

.font-2xl {
  font-size: var(--font-2xl) !important;
}

.font-3xl {
  font-size: var(--font-3xl) !important;
}

.font-4xl {
  font-size: var(--font-4xl) !important;
}

/*FONT----WEIGHT----CLASSES----START*/
.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

/*COLORS-----START*/
.color-white {
  color: var(--white) !important;
}

.color-skyblue {
  color: var(--sky-blue) !important;
}

.color-gray {
  color: var(--gray) !important;
}

.bg-darkblue {
  background-color: var(--dark-blue) !important;
}

.text-linear-gradient {
  background: linear-gradient(90deg, #fc00ff 0%, #00dbde 68.23%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/*COMMON CLASSES STARTS---------------*/
.main-heading {
  font-size: var(--font-3xl);
  font-weight: 700;
  color: var(--white);
  font-family: "Poppins", sans-serif;
}

.main-para {
  font-size: var(--font-md);
  font-weight: normal;
  font-family: "Inter", sans-serif;
  color: var(--white);
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-07 {
  opacity: 0.7;
}

.z-index-1 {
  z-index: 1;
}

.cursor_pointer {
  cursor: pointer;
}

.overflow_hidden {
  overflow: hidden;
}

/*EASY TO USE CSS START------------------------------*/
.easy_to_use_bg {
  background-image: url("../src/assets/img/easy-to-use-bg.png");
  min-height: 93vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.easy_to_use_cards {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.33);
  backdrop-filter: blur(54px);
  border-radius: 8px;
  padding: 33px 17px;
  transition: all 0.15s ease-in-out;
  cursor: default;
}

.easy_to_use_cards:hover {
  transform: translateY(-10px);
  box-shadow: 0px 0px 15px rgba(94, 0, 255, 0.82);
}

.shadow_img {
  right: 13%;
  top: -17%;
  z-index: -1;
}

.cards_img {
  width: 100%;
  max-height: 140px;
}

/*DEFI API CSS START-----------------------*/
.defi_api_bg {
  min-height: 512px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.defi_shadow_one {
  top: 30px;
  right: 46%;
}

.defi_btn {
  padding: 13px 25px;
}

.defi_img_card {
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: 1.08852px solid rgba(255, 255, 255, 0.33);
  -webkit-backdrop-filter: blur(29.3902px);
  backdrop-filter: blur(29.3902px);
  border-radius: 8.7082px;
}

.dots {
  height: 10.24px;
  width: 10.24px;
  background: var(--white);
  border-radius: 50%;
  content: "";
  display: inline-block;
  margin-top: 10px;
}

.black_strip {
  background: #000;
  opacity: 0.3;
  width: 100%;
  height: 35px;
}

.text_card {
  padding: 30px 12px 0 0;
}

.text_card span {
  font-family: monospace;
  font-size: var(--font-md);
  padding-top: 0px;
  padding-left: 20px;
  overflow-wrap: anywhere;
}

.defi_card_link {
  color: var(--white);
}

.text_block {
  display: block;
  margin-left: 24px;
}

/*EVM CHAINS CSS START-------------------------------*/
.evm_bg {
  background: rgba(7, 4, 43, 1);
}

.evm_img {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 59px;
  width: 59px;
}

.evm_img:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

/*WEB3 CSS START------------------------------------*/
.web3_bg {
  background: rgba(7, 4, 43, 1);
}

.web3_card_bg {
  background: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(54px);
  backdrop-filter: blur(54px);
  border-radius: 8px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  min-width: 200px;
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.web3_card_bg:hover {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
  -webkit-box-shadow: 0px 0px 15px #fc00ff;
  box-shadow: 0px 0px 15px #fc00ff;
}

.web3_card_para {
  max-width: 145px;
}

/*ABOUT PORTALS CSS START----------------------*/
.about_portal_sec {
  padding: 100px 0px;
}

/*BACK TO TOP CSS START-------------*/
.topscroll {
  position: fixed;
  right: 25px;
  bottom: 45px;
  background: var(--white);
  height: 40px;
  width: 40px;
  z-index: 100;
  border-radius: 50%;
  cursor: pointer;
  animation: move 4s ease-in-out infinite;
  transition: all 0.1s ease-in-out;
}

.topscroll:hover {
  color: var(--white);
  background: rgb(120, 43, 255);
  box-shadow: 0px 0px 25px rgba(94, 0, 255, 0.42);
}

@keyframes move {
  0% {
    transform: translatey(0px) scale(0.998);
  }

  50% {
    transform: translatey(-7px) scale(1.1023);
  }

  100% {
    transform: translatey(0px) scale(0.998);
  }
}

@media (min-width: 1400px) and (max-width: 1599.98px) {
  .container-modified {
    max-width: 1140px !important;
    margin: auto;
  }
}

@media (max-width: 1199.98px) {
  .defi_img_card {
    margin: auto;
  }

  .defi_api_bg {
    min-height: 760px;
  }
}

@media (max-width: 991.98px) and (min-width: 630px) {
  .defi_shadow_one {
    top: -30px;
    right: 100px;
  }
}

@media (max-width: 630px) {
  .defi_shadow_one {
    top: 0px;
    right: 160px;
  }
}

@media (max-width: 575.98px) {
  .defi_btn {
    padding: 10px 20px;
  }

  .easy_to_use_cards {
    padding: 20px 5px;
    max-width: 300px;
    margin: auto;
  }

  .topscroll {
    height: 40px;
    right: 10px;
    bottom: 30px;
    width: 40px;
  }

  .web3_card_bg {
    min-width: 133px !important;
    height: 150px;
    padding: 0px 15px;
  }

  .about_portal_sec {
    padding: 40px 0px;
  }
}

@media (max-width: 500px) {
  .defi_img_card {
    width: 290px;
  }

  .black_strip {
    width: 290px;
  }
}
